import React from "react";
import "./SoftwareSkill.scss";
import {skillsSection} from "../../portfolio";

let skillSvg = {
  "flutter.svg": (
    <svg
      height="317"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 317"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <linearGradient
        id="a"
        x1="3.951709%"
        x2="75.897073%"
        y1="26.993029%"
        y2="52.919266%"
      >
        <stop offset="0" />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <path
        d="m157.665785.00054936-157.66523564 157.66523564 48.80041204 48.800412 206.4667466-206.46564764z"
        fill="currentColor"
      />
      <path
        d="m156.567183 145.396793-84.4184723 84.418472 48.9838973 49.71564 48.710317-48.710318 85.424893-85.423794z"
        fill="currentColor"
      />
      <path
        d="m121.133047 279.531124 37.081545 37.081546h97.052567l-85.424893-85.791863z"
        fill="currentColor"
      />
      <path
        d="m71.5995742 230.364072 48.8015108-48.801511 49.440961 49.258575-48.709219 48.710318z"
        fill="currentColor"
      />
      <path
        d="m121.132827 279.531454 40.560069-13.459227 4.028979-31.130919z"
        fill="currentColor"
        fill-opacity=".8"
      />
    </svg>
  ),
  "dart.svg": (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m4.105 4.105s5.053-2.525 7.579-3.789a3.079 3.079 0 0 1 1.481-.315c.766.047 1.677.788 1.677.788l9.158 9.159v9.789h-4.263v4.263h-9.948l-9-9c-.486-.5-.789-1.205-.789-1.895 0-.319.18-.818.316-1.105zm.679.679v11.787c.002.543.021 1.024.498 1.508l4.922 4.921h8.533v-4.263zm12.055-.678c-.899-.896-1.809-1.78-2.74-2.643-.302-.267-.567-.468-1.07-.462-.37.014-.87.195-.87.195l-5.818 2.909z"
        fill="currentColor"
      />
    </svg>
  ),
  "typescript.svg": (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m0 12v12h24v-24h-24zm19.341-.956c.61.152 1.074.423 1.501.865.221.236.549.666.575.77.008.03-1.036.73-1.668 1.123-.023.015-.115-.084-.217-.236-.31-.45-.633-.644-1.128-.678-.728-.05-1.196.331-1.192.967a.88.88 0 0 0 .102.45c.16.331.458.53 1.39.933 1.719.74 2.454 1.227 2.911 1.92.51.773.625 2.008.278 2.926-.38.998-1.325 1.676-2.655 1.9-.411.073-1.386.062-1.828-.018-.964-.172-1.878-.648-2.442-1.273-.221-.243-.652-.88-.625-.925a1.58 1.58 0 0 1 .22-.141c.108-.061.511-.294.892-.515l.69-.4.145.214c.202.308.643.731.91.872.766.404 1.817.347 2.335-.118a.883.883 0 0 0 .313-.72c0-.278-.035-.4-.18-.61-.186-.266-.567-.49-1.649-.96-1.238-.533-1.771-.864-2.259-1.39a3.165 3.165 0 0 1 -.659-1.2c-.091-.339-.114-1.189-.042-1.531.255-1.197 1.158-2.03 2.461-2.278.423-.08 1.406-.05 1.821.053zm-5.634 1.002.008.983h-3.125v8.876h-2.21v-8.876h-3.122v-.964c0-.534.011-.98.026-.99.012-.016 1.913-.024 4.217-.02l4.195.012z"
        fill="currentColor"
      />
    </svg>
  )
};

export default function SoftwareSkill() {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skills, i) => {
            if (skills.iconPath !== undefined) {
              return (
                <li key={i} className="software-skill-inline">
                  {skillSvg[skills.iconPath]}
                  <p>{skills.skillName}</p>
                </li>
              );
            } else {
              return (
                <li
                  key={i}
                  className="software-skill-inline"
                  name={skills.skillName}
                >
                  <i className={skills.fontAwesomeClassname}></i>
                  <p>{skills.skillName}</p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
}
